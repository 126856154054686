export const devFirebaseConfig = {
  apiKey: "AIzaSyDCwWYceISoVbZBb1pws2ftUq1vroM5xhA",
  authDomain: "azt-admin-d20.haiidev.co.kr",
  projectId: "haii-d-prj-azt-d20",
  storageBucket: "haii-d-prj-azt-d20.appspot.com",
  messagingSenderId: "1046978745504",
  appId: "1:1046978745504:web:9d68c4b08c2c4e05d725c5",
};

export const prodFirebaseConfig = {
  apiKey: "AIzaSyB3bFR744Fv4BLlZgZCWIJKpbe4yQHxd9k",
  authDomain: "azt-admin-d20.haii.io",
  projectId: "haii-p-prj-azt-d20",
  storageBucket: "haii-p-prj-azt-d20.appspot.com",
  messagingSenderId: "493087889060",
  appId: "1:493087889060:web:ea4412306507a60a36b831",
};

export const devD20FirebaseConfig = {
  apiKey: "AIzaSyDCwWYceISoVbZBb1pws2ftUq1vroM5xhA",
  authDomain: "azt-admin-d20.haiidev.co.kr",
  projectId: "haii-d-prj-azt-d20",
  storageBucket: "haii-d-prj-azt-d20.appspot.com",
  messagingSenderId: "1046978745504",
  appId: "1:1046978745504:web:9d68c4b08c2c4e05d725c5",
};

export const prodD20FirebaseConfig = {
  apiKey: "AIzaSyB3bFR744Fv4BLlZgZCWIJKpbe4yQHxd9k",
  authDomain: "azt-admin-d20.haii.io",
  projectId: "haii-p-prj-azt-d20",
  storageBucket: "haii-p-prj-azt-d20.appspot.com",
  messagingSenderId: "493087889060",
  appId: "1:493087889060:web:ea4412306507a60a36b831",
};
