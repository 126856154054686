import type { VuetifyLocale } from "vuetify/types/services/lang";

import { ko } from "@/plugins/locale/ko";
import { en } from "@/plugins/locale/en";

export type $CommonType = VuetifyLocale & {
  alzguard: string;
  all: string;
  cancel: string;
  confirmDelete: string;
  delete: string;
  modeDevops: string;
  none: string;
  ok: string;
  or: string;
  subTitle: string;
  title: string;
  today: string;
  unknown: string;
  weekdaysLong: string;
  weekdaysShort: string;
  yesterday: string;
  startDt: string;
  endDt: string;
  testDate: string;
  validateDate: string;
  userName: string;
  age: string;
  gender: string;
  index: string;
  male: string;
  female: string;
  language: string;
  testerFlag: string;
  checkTesterFlag: string;
  total: string;
  userKey: string;
  birthDT: string;
  phone: string;
  startTM: string;
  endTM: string;
  completedFlag: string;
  noData: string;
  onEdit: string;
  editSuccess: string;
  phoneFormatError: string;
  registerSuccess: string;
  registration: string;
  activity: string;
  person: string;
};

export type gameGroupCommonType = {
  gameGroup: string;
  deactivatedGames: string;
  activatedGames: string;
  gameName: string;
  AllDeactivated: string;
  AllActivated: string;
};

export type institutionCommonType = {
  institutionName: string;
  couponCode: string;
  responsibility: string;
  periodOfUse: string;
  codeActivity: string;
  model: string;
  email: string;
  password: string;
  emailExisted: string;
  registerInstitution: string;
  gameGroup: string;
};

type CistType = {
  name: string;
  age: string;
  edu: string;
  eduYear: string;
  kMMSETotal: string;
  kMMSEPercent: string;
  cistTotal: string;
  cistResult: string;
  diagnosis: string;
};

type ReportListType = $CommonType &
  institutionCommonType & {
    historyUUID: string;
    gameGroupName: string;
    completedFlag: string;
    phoneFormatError: string;
    editInfo: string;
  };

type ReportType = $CommonType & {
  noGameData: string;
  basicInfo: string;
  hospitalExaminationResults: string;
};

type SignInType = $CommonType & {
  googleLoginMessage: string;
  emailLoginMessage: string;
};

type ShowImageType = $CommonType & {
  btnName: string;
  noImage: string;
};

type ResultSummaryType = $CommonType & {
  totalNumOfQuestion: string;
  correctAnswerNum: string;
  playTime: string;
  totalResponseTime: string;
  averageResponseTime: string;
  question: string;
  answer: string;
  responseTime: string;
};

type GameDetailInfoType = $CommonType & {
  detailData: string;
};

type NavigationMenuListType = $CommonType & {
  report: string;
  alzguardReport: string;
  userManagement: string;
  gameGroupManagement: string;
  serviceManagement: string;
  InstitutionList: string;
};

type PlayAudioType = $CommonType & {
  playAudio: string;
};

type BannerType = $CommonType & {
  update: string;
  refresh: string;
};

type ExcelDataUploadType = $CommonType & {
  inputSheet: string;
  inputDate: string;
  inputFile: string;
  missMatchedResult: string;
  sheetName: string;
  sheetInputHint: string;
};

type UserType = $CommonType &
  institutionCommonType & {
    basicInfo: string;
    registerDate: string;
    phoneFormatError: string;
    detailTests: string;
  };

type DialogEditHistoryUserInfoType = $CommonType & {
  editTitle: string;
};

type GameGroupListType = $CommonType &
  gameGroupCommonType & {
    demoGameGroup: string;
  };

type GamesType = $CommonType & gameGroupCommonType;

type CreateGameGroupType = $CommonType &
  gameGroupCommonType & {
    createGameGroup: string;
    inputGameGroupName: string;
    demoOrNot: string;
    gameGroupNameInputHint: string;
  };

type ConfirmDialogType = $CommonType &
  institutionCommonType &
  gameGroupCommonType;

type InstitutionListType = $CommonType & institutionCommonType;

type RegisterInstitutionType = $CommonType &
  institutionCommonType &
  gameGroupCommonType & {
    generateRandom: string;
    checkOverlap: string;
    reset: string;
  };

type InstitutionType = $CommonType &
  institutionCommonType & {
    editPassword: string;
  };

type BasicInfoType = $CommonType &
  institutionCommonType & {
    registerDate: string;
  };

type DashboardBasicInfoType = $CommonType & {
  basicInfo: string;
  totalParticipantsNum: string;
  participantsByInstitution: string;
  genderRate: string;
  ageRate: string;
  normal: string;
  risk: string;
  unknown: string;
  ageGroup: string;
  more: string;
};

type ActiveInfo = $CommonType & {
  activeInfo: string;
  dau: string;
  revisitRate: string;
  exDayRateUpAndDown: string;
  exMonthRateUpAndDown: string;
  useAveragePerPerson: string;
};

type ExcelDownloadBtnType = $CommonType & {
  userFlag: string;
};

export type LocaleType = VuetifyLocale & {
  ReportList: ReportListType;
  AppBar: $CommonType;
  SignIn: SignInType;
  ShowImage: ShowImageType;
  ResultSummary: ResultSummaryType;
  BasicInfo: BasicInfoType;
  GameDetailInfo: GameDetailInfoType;
  NavigationMenuList: NavigationMenuListType;
  Report: ReportType;
  PlayAudio: PlayAudioType;
  Banner: BannerType;
  ExcelDataUpload: ExcelDataUploadType;
  NavigationSetting: $CommonType;
  Pagination: $CommonType;
  UserList: $CommonType & institutionCommonType;
  User: UserType;
  DialogEditHistoryUserInfo: DialogEditHistoryUserInfoType;
  MergeDialog: $CommonType & CistType;
  CistResult: $CommonType & CistType;
  GameGroupList: GameGroupListType;
  Games: GamesType;
  InstitutionList: InstitutionListType;
  RegisterInstitution: RegisterInstitutionType;
  Institution: InstitutionType;
  ConfirmDialog: ConfirmDialogType;
  CreateGameGroup: CreateGameGroupType;
  DashboardBasicInfo: DashboardBasicInfoType;
  ActiveInfo: ActiveInfo;
  Dashboard: $CommonType;
  ExcelDownloadBtn: ExcelDownloadBtnType;
};

export default {
  ko,
  en,
};
