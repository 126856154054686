import vuetifyKO from "vuetify/src/locale/ko";

import {
  $CommonType,
  gameGroupCommonType,
  institutionCommonType,
  LocaleType,
} from ".";

const $Common: $CommonType = {
  alzguard: "Alzguard",
  all: "전체",
  cancel: "취소",
  confirmDelete: "삭제하면 복구할 수 없습니다. 계속하시겠습니까?",
  delete: "삭제",
  modeDevops: "개발자 모드",
  none: "없음",
  ok: "확인",
  or: "또는",
  subTitle: "",
  title: "",
  today: "오늘",
  unknown: "모름",
  weekdaysLong: "일요일 월요일 화요일 수요일 목요일 금요일 토요일",
  weekdaysShort: "일 월 화 수 목 금 토",
  yesterday: "어제",
  startDt: "시작 날짜",
  endDt: "종료 날짜",
  testDate: "테스트 날짜",
  validateDate: "날짜를 입력해주세요",
  userName: "이름",
  age: "나이",
  gender: "성별",
  index: "#",
  male: "남성",
  female: "여성",
  language: "언어",
  testerFlag: "테스트 유저 여부",
  checkTesterFlag: "테스트 유저만 보기",
  total: "Total",
  userKey: "유저 Key",
  birthDT: "생년월일",
  phone: "전화번호",
  startTM: "테스트 시작 시간",
  endTM: "테스트 종료 시간",
  completedFlag: "전체 게임 완료 상태",
  noData: "데이터가 없습니다.",
  onEdit: "Save",
  editSuccess: "수정이 완료되었습니다.",
  registerSuccess: "등록이 완료되었습니다.",
  phoneFormatError: "휴대폰 형식이 맞지 않습니다. 확인해 주세요",
  registration: "등록",
  activity: "활성 여부",
  person: "명",
};

const institutionCommonType: institutionCommonType = {
  institutionName: "기관명",
  couponCode: "이용권 코드",
  responsibility: "담당자",
  periodOfUse: "이용 기간",
  codeActivity: "코드 활성 여부",
  model: "거래 유형",
  email: "이메일 주소",
  password: "비밀번호",
  emailExisted: "해당 이메일이 이미 존재합니다.",
  registerInstitution: "기관 등록",
  gameGroup: "게임 그룹",
};

const gameGroupCommonType: gameGroupCommonType = {
  gameGroup: "게임 그룹",
  deactivatedGames: "비활성화 게임",
  activatedGames: "활성화 게임",
  gameName: "게임명",
  AllDeactivated: "모두 비활성화",
  AllActivated: "모두 활성화",
};

export const ko: LocaleType = {
  ...vuetifyKO,

  ReportList: {
    ...$Common,
    ...institutionCommonType,
    title: "알츠가드 보고서",
    historyUUID: "historyUUID",
    gameGroupName: "게임 그룹명",
    completedFlag: "전체 게임 완료 상태",
    result: "결과",
    downloadReport: "결과보고서 다운로드",

    editInfo: "정보 수정",
  },
  AppBar: $Common,
  SignIn: {
    ...$Common,
    subTitle: "안녕하세요 알츠가드 어드민입니다.",
    googleLoginMessage: "구글로 로그인하기",
    emailLoginMessage: "이메일로 로그인하기",
  },
  ShowImage: {
    ...$Common,

    btnName: "이미지 보기",
    noImage: "이미지가 없습니다.",
  },
  ResultSummary: {
    ...$Common,
    totalNumOfQuestion: "총 문제 수",
    correctAnswerNum: "정답 수",
    playTime: "소요 시간",
    totalResponseTime: "반응 시간 합",
    averageResponseTime: "평균 반응 시간",
    question: "문제",
    answer: "정답 여부",
    responseTime: "반응 속도",
  },
  BasicInfo: {
    ...$Common,
    ...institutionCommonType,
    registerDate: "가입 일자",
  },
  GameDetailInfo: {
    ...$Common,
    detailData: "상세 데이터",
  },
  NavigationMenuList: {
    ...$Common,
    report: "보고서",
    alzguardReport: "알츠가드 보고서",
    userManagement: "사용자 관리",
    gameGroupManagement: "게임 그룹 관리",
    serviceManagement: "서비스 관리",
    InstitutionList: "기관 목록",
  },
  Report: {
    ...$Common,
    noGameData: "게임 데이터가 없습니다.",
    basicInfo: "기본 정보",
    hospitalExaminationResults: "병원 검사 결과",
    resultReport: "결과보고서",
  },
  PlayAudio: {
    ...$Common,

    playAudio: "오디오 듣기",
    noData: "오디오 데이터가 없습니다.",
  },
  Banner: {
    ...$Common,

    update: "새로운 업데이트 내역이 있습니다. 새로고침 해주세요.",
    refresh: "새로고침",
  },
  ExcelDataUpload: {
    ...$Common,
    title: "병원데이터 업로드",
    testDate: "테스트 날짜",
    inputDate: "테스트 날짜를 입력해주세요",
    inputFile: "업로드할 파일을 넣어주세요",
    inputSheet: "시트명을 입력해주세요",
    missMatchedResult: "매칭 안된 리스트",
    sheetName: "시트명",
    sheetInputHint:
      "CIST 결과가 있는 엑셀 시트 이름을 입력해주세요(대소문자 구분 필수)",
  },
  NavigationSetting: {
    ...$Common,
  },
  Pagination: {
    ...$Common,
  },
  UserList: {
    ...$Common,
    ...institutionCommonType,
    title: "사용자 리스트",
  },
  User: {
    ...$Common,
    ...institutionCommonType,
    basicInfo: "기본 정보",
    detailTests: "테스트별 상세보기",
    registerDate: "가입 일자",
  },
  DialogEditHistoryUserInfo: {
    ...$Common,
    editTitle: "데이터 수정",
  },
  MergeDialog: {
    ...$Common,
    title: "동일한 유저가 존재합니다. 계속 수정하시겠습니까?",
    edu: "학력",
    eduYear: "교육년수",
    kMMSETotal: "K-MMSE-2(B) Total Score",
    kMMSEPercent: "K-MMSE-2(B) Percentile",
    cistTotal: "CIST Total Score",
    cistResult: "CIST_result(정상:0, 인지저하의심:1)",
    name: "",
    diagnosis: "병원 진단 결과 Normal: 0, MCI: 1, Dementia: 2",
  },
  CistResult: {
    ...$Common,
    edu: "학력",
    eduYear: "교육년수",
    kMMSETotal: "K-MMSE-2(B) Total Score",
    kMMSEPercent: "K-MMSE-2(B) Percentile",
    cistTotal: "CIST Total Score",
    cistResult: "CIST_result(정상:0, 인지저하의심:1)",
    name: "",
    diagnosis: "병원 진단 결과 (Normal: 0, MCI: 1, Dementia: 2)",
  },

  GameGroupList: {
    ...$Common,
    ...gameGroupCommonType,
    demoGameGroup: "데모용 게임 그룹",
  },
  Games: {
    ...$Common,
    ...gameGroupCommonType,
  },
  InstitutionList: {
    ...$Common,
    ...institutionCommonType,
    title: "기관 목록",
  },
  RegisterInstitution: {
    ...$Common,
    ...institutionCommonType,
    ...gameGroupCommonType,
    generateRandom: "랜덤 생성",
    institutionName: "기관명 (필수 입력)",
    responsibility: "담당자 (필수 입력)",
    email: "이메일 주소 (필수 입력)",
    password: "비밀번호 (필수 입력)",
    model: "거래 유형 (필수 입력)",
    couponCode: "이용권 코드 (필수 입력)",
    checkOverlap: "중복체크",
    reset: "재설정",
  },
  Institution: {
    ...$Common,
    ...institutionCommonType,
    title: "기관 상세보기",
    editPassword: "비밀번호(필수 아님)",
  },
  ConfirmDialog: {
    ...$Common,
    ...institutionCommonType,
    ...gameGroupCommonType,
  },
  CreateGameGroup: {
    ...$Common,
    ...gameGroupCommonType,
    createGameGroup: "게임 그룹 생성",
    inputGameGroupName: "게임 그룹명 입력",
    demoOrNot: "데모 게임 그룹 여부",
    gameGroupNameInputHint:
      "DEMO 게임 그룹으로 설정 시 자동으로 DEMO_OO 으로 설정됩니다.",
  },
  DashboardBasicInfo: {
    ...$Common,
    basicInfo: "기본 정보",
    totalParticipantsNum: "Alzguard 참여자 수",
    participantsByInstitution: "기관 별 참여자 수",
    genderRate: "성별 비율",
    ageRate: "연령별 비율",
    normal: "정상",
    risk: "위험",
    unknown: "알 수 없음",
    ageGroup: "대",
    more: "이상",
  },
  ActiveInfo: {
    ...$Common,
    activeInfo: "활동 정보",
    dau: "일일 활성 사용자 수",
    revisitRate: "전체 재방문 비율",
    exDayRateUpAndDown: "어제 기준 증감률",
    exMonthRateUpAndDown: "전월 기준 증감률",
    useAveragePerPerson: "인당 1회 평균 사용 시간",
  },
  Dashboard: {
    ...$Common,
  },
  ExcelDownloadBtn: {
    ...$Common,
    title: "엑셀 다운로드",
    ok: "다운로드",
    userFlag: "개인정보 포함 여부",
  },
};
